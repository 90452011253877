<template>
  <div
    class="c-app flex-row align-items-center login"
    :class="{ 'c-dark-theme': $store.state.darkMode }"
  >
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6" class="login-logo">
          <img src="img/jogg-logo-white.svg" /><br />
          Management Portal
        </CCol>
      </CRow>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Login</h1>
                  <CInput
                    placeholder="Phone"
                    autocomplete="phone"
                    v-model="email"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-phone"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    v-model="password"
                    autocomplete="curent-password"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton
                        color="link"
                        class="px-0"
                        @click="onForgotPasswordClick"
                        >Forgot password?</CButton
                      >
                      <CModal
                        title="Forgot password"
                        :show.sync="forgotPasswordModal"
                        centered
                        :closeOnBackdrop="false"
                      >
                        <CInput
                          v-if="!isCodeReceived"
                          placeholder="Enter your phone number to recieve code"
                           prepend="+1"
                          v-model="currentEmail"
                        />
                        <CInput
                          v-if="isCodeReceived"
                          placeholder="Enter Code"
                          v-model="code"
                        />
                        <CInput
                          v-if="isCodeReceived"
                          placeholder="Enter new password"
                          type="password"
                          v-model="newPassword"
                        />
                        <template v-slot:footer>
                          <CButton
                            color="primary"
                            class="px-4"
                            @click="cancelModal"
                            >Cancel</CButton
                          >
                          <CButton
                            color="primary"
                            class="px-4"
                            @click="enterPasswordCode"
                            >Submit</CButton
                          >
                        </template>
                      </CModal>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="primary" class="px-4" @click="login"
                        >Login</CButton
                      >
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import constants from "../data/Constants.js";
import { Auth } from "aws-amplify";
import AuthenticationService from "../services/authenticationservice.js";
import VueSimpleAlert from "vue-simple-alert";
import store from "../store.js";
import DynamoDBService from "../services/dynamodbservice.js";
export default {
  name: "Login",
  data() {
    return {
      loader: "",
      email: "", // rajesh.gv1186@gmail.com webteam@techpro.com 6303588734// DEV
      password: "", // Password1! //DEV
      authInfo: "",
      forgotPasswordModal: false,
      code: "",
      newPassword: "",
      isCodeReceived: false,
      currentEmail: "",
    };
  },
  mounted: function() {
    store.commit("set", ["selectedSite", null]);
    store.commit("set", ["selectedCategory", null]);
    store.commit("set", ["permissions", {}]);
    store.commit("set", ["currentUser", {}]);
  },
  methods: {
    enterPasswordCode() {
      // validation start
      if (this.isCodeReceived) {
        if (this.code == undefined || this.code == null || this.code == "") {
          this.$alert(constants.CODE_VALIDATION, constants.INFORMATION);
          return;
        }
        if (
          this.newPassword == undefined ||
          this.newPassword == null ||
          this.newPassword == ""
        ) {
          this.$alert(constants.NEW_PASSWORD_VALIDATION, constants.INFORMATION);
          return;
        }
      } else {
        // if (
        //   this.currentEmail == undefined ||
        //   this.currentEmail == null ||
        //   this.currentEmail == ""
        // ) {
        //   this.$alert(constants.EMAIL_VALIDATION, constants.INFORMATION);
        //   return;
        // }
        // if (!this.ValidateEmail(this.currentEmail)) {
        //   this.$alert(constants.EMAIL_VALID_VALIDATION, constants.INFORMATION);
        //   return;
        // }
      }
      ///  validation end
      if (!this.isCodeReceived) {
        this.loader = this.$loading.show();
        const authenticationService = new AuthenticationService();
        authenticationService.forgotPassword("+1"+this.currentEmail).then(
          (data) => {
            this.loader.hide();
            var test = data;

            this.isCodeReceived = true;
          },
          (error) => {
            this.isCodeReceived = false;
            this.loader.hide();
            console.log(error); // user not confirmed with email will trigger here.
            this.$alert(error.message);
          }
        );
      } else {
        this.loader = this.$loading.show();
        const authenticationService = new AuthenticationService();
        authenticationService
          .forgotPasswordWithCode(
            "+1"+this.currentEmail,
            this.code,
            this.newPassword
          )
          .then(
            (data) => {
              this.loader.hide();
              var test = data;
              this.currentEmail = "";
              this.code = "";
              this.newPassword = "";
              this.isCodeReceived = false;
              this.forgotPasswordModal = false;
            },
            (error) => {
              this.isCodeReceived = false;
              this.loader.hide();
              console.log(error); // user not confirmed with email will trigger here.
              this.$alert(error.message);
            }
          );
      }
    },
    onForgotPasswordClick() {
      this.forgotPasswordModal = true;
    },
    cancelModal() {
      this.currentEmail = "";
      this.code = "";
      this.newPassword = "";
      this.forgotPasswordModal = false;
      this.isCodeReceived = false;
    },
    login() {
      //  const authenticationService = new AuthenticationService();
      // authenticationService
      //   .createUser('webteam@techpro.com','Password1!','+16303588734' )
      //   .then(
      //     (data) => {
      //       this.authInfo = data;
      //       var params = {
      //         TableName: constants.USERS_TABLE_NAME,
      //         Item: {
      //           userid: 'webteam@techpro.com',
      //           name: 'TechPro Webteam',
      //           sites: 'S-1011,S-9002,S-1098,S-1621354709086,S-1005,S-1006,S-1010',
      //           jogg_role: 'admin',
      //           status: 'true',
      //           phone_number:'+16303588734'
      //         },
      //       };
      //       const dynamoDBService = new DynamoDBService();
      //       this.credentials = dynamoDBService.getCredentials();
      //       dynamoDBService.addItem(params).then(
      //         (data) => {
      //           console.log(data);
      //           //this.$router.push({ path: "/users" });
      //         },
      //         (error) => {
      //           console.log(error);
      //           this.$alert(error.message, constants.INFORMATION);
      //         }
      //       );
      //     },
      //     (error) => {
      //       console.log(error);
      //       this.$alert(error.message, constants.INFORMATION);
      //     }
      //   );
      //   return;
      // temp code to create table column with data for permissions.
      /* var params = {
        TableName: 'permissions',
        Item: {
          role_name:'manager',
          edit_delivery_fee: false,
          edit_jogg_commission: false,
          edit_discount_sales_tax: false,
          edit_site: false,
          view_users: false,
          view_bank_account_info: true,
          view_past_order: true,
          view_menus: true,
        },
      };
      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.addItem(params).then(
        (data) => {
          console.log(data);
        },
        (error) => {
          console.log(error);
          this.$alert(error.message, constants.INFORMATION);
        }
      );
      return;*/
      if (this.isValid()) {
        this.loginProceed();
      }
    },

    async loginProceed() {
      var check = this.email.includes("+1");
      if (check == false) {
        this.email = "+1" + this.email;
      }
      this.loader = this.$loading.show();
      const authenticationService = new AuthenticationService();
      authenticationService.login(this.email, this.password).then(
        (data) => {
          this.authInfo = data;
          this.getItem(data.attributes.email);
        },
        (error) => {
          this.loader.hide();
          console.log(error); // user not confirmed with email will trigger here.
          this.$alert(error.message);
        }
      );
    },
    isValid() {
      var isValidFlag = true;
      if (this.email == undefined || this.email == null || this.email == "") {
        isValidFlag = false;
        this.$alert(constants.USERNAMEVALIDATION, constants.INFORMATION);
        return;
      }
      if (
        this.password == undefined ||
        this.password == null ||
        this.password == ""
      ) {
        isValidFlag = false;
        this.$alert(constants.PASSWORDVALIDATION, constants.INFORMATION);
        return;
      }
      return isValidFlag;
    },
    ValidateEmail(mail) {
      if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          mail
        )
      ) {
        return true;
      }
      return false;
    },
    getItem(username) {
      var that = this;
      var params = {
        TableName: constants.USERS_TABLE_NAME,
        Key: { userid: username },
      };
      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.getItem(params).then(
        (data) => {
          this.loader.hide();
          if (Object.keys(data).length > 0) {
            if (data.Item.jogg_role != "jogger") {
              //store.state.currentUser = data.Item;
              that.$store.commit("set", ["currentUser", data.Item]);
              var params1 = {
                TableName: constants.PERMISSIONS_TABLE_NAME,
                Key: { role_name: store.state.currentUser.jogg_role },
              };
              dynamoDBService.getItem(params1).then(
                (data1) => {
                  this.loader.hide();
                  if (data1 != undefined || data1 != null) {
                    //store.state.permissions = data1.Item;
                    that.$store.commit("set", ["permissions", data1.Item]);

                    this.$router.push("/dashboard");
                  }
                },
                (error) => {
                  this.loader.hide();
                  console.log(error);
                }
              );
            } else {
              this.$alert(constants.JOGGER_NOT_ALLOWED, constants.INFORMATION);
              return;
            }
          } else {
            this.$alert(constants.NO_USER_FOUND, constants.INFORMATION);
            return;
          }
        },
        (error) => {
          this.loader.hide();
          console.log(error);
        }
      );
    },
  },
};
</script>
